import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import L from "leaflet";
import Papa from "papaparse";
import * as turf from "@turf/turf";
import "leaflet/dist/leaflet.css";
import "./TravelMap.css";

// Create a custom icon for visited locations
const CustomMarkerIcon = L.divIcon({
  className: "custom-marker",
  html: '<div class="marker-pin"></div>',
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  popupAnchor: [0, -40],
});

// Create a custom icon for future locations
const FutureMarkerIcon = L.divIcon({
  className: "custom-marker-future",
  html: '<div class="marker-pin-future"></div>',
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  popupAnchor: [0, -40],
});

// Custom hook to manage map bounds and estimation
const MapBoundsEstimator = ({ onBoundsChange }) => {
  const map = useMap();

  useEffect(() => {
    const handleMoveEnd = () => {
      const bounds = map.getBounds();
      const southWest = bounds.getSouthWest();
      const northEast = bounds.getNorthEast();
      const visibleWidth = northEast.lng - southWest.lng;
      const visibleHeight = northEast.lat - southWest.lat;

      const visiblePercentage = (visibleWidth / 360) * (visibleHeight / 180) * 100;
      onBoundsChange(visiblePercentage);
    };

    map.on('moveend', handleMoveEnd);
    handleMoveEnd();

    return () => {
      map.off('moveend', handleMoveEnd);
    };
  }, [map, onBoundsChange]);

  return null;
};

// Legend Component
const Legend = () => {
  const map = useMap();

  useEffect(() => {
    const legend = L.control({ position: "bottomright" });

    legend.onAdd = () => {
      const div = L.DomUtil.create("div", "info legend");
      div.innerHTML =
        '<div><i style="background:#ff0000"></i> Visited Locations</div>' +
        '<div><i style="background:#00ccff"></i> Future Locations</div>';
      return div;
    };

    legend.addTo(map);

    return () => {
      map.removeControl(legend);
    };
  }, [map]);

  return null;
};

function TravelMap() {
  const [locations, setLocations] = useState([]);
  const [futureLocations, setFutureLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [worldTravelPercentage, setWorldTravelPercentage] = useState(0);
  const [mapCenter, setMapCenter] = useState([1.3521, 103.8198]);

  const cityImages = {
    Sydney: ["1.jpg"],
    Davis: ["1.jpg"],
    Kuta: ["1.jpg"],
    SanFrancisco: ["1.jpg"],
    Sacramento: ["1.jpg"],
    BlueMountains: ["1.jpg"],
    BondiBeach: ["1.jpg"],
    Pecatu: ["1.jpg"],
    KedongananBeach: ["1.jpg"],
    Tegallalang: ["1.jpg"],
    Seminyak: ["1.jpg"],
    Kemenuh: ["1.jpg"],
    Ubud: ["1.jpg"],
    BronteBeach: ["1.JPG"],
    Denpasar: ["1.jpg"],
    KabupatenBadung: ["1.jpg"],
    PalmBeach: ["1.jpg"],
    Gulmarg: ["1.jpg"],
    Sonamarg: ["1.jpg"],
    Basohli: ["1.jpg"],
    Srinagar: ["1.jpg"],
    Patnitop: ["1.jpg"],
    Mayabunder: ["1.jpg"],
    CellularJail: ["1.jpg"],
    PortBlair: ["1.jpg"],
    Kargil: ["1.jpg"],
    BalmoralBeach: ["1.jpg"],
    DudleyPageReserve: ["1.jpg"],
  };

  // Load travel history data
  useEffect(() => {
    Papa.parse(process.env.PUBLIC_URL + "/travel_history.csv", {
      download: true,
      header: true,
      complete: (results) => {
        const data = results.data
          .map((item) => ({
            ...item,
            latitude: parseFloat(item.latitude),
            longitude: parseFloat(item.longitude),
          }))
          .filter((item) => !isNaN(item.latitude) && !isNaN(item.longitude));

        setLocations(data);

        if (data.length > 0) {
          const latSum = data.reduce((sum, loc) => sum + loc.latitude, 0);
          const lngSum = data.reduce((sum, loc) => sum + loc.longitude, 0);
          const avgLat = latSum / data.length;
          const avgLng = lngSum / data.length;
          setMapCenter([avgLat, avgLng]);
        }
      },
    });

    Papa.parse(process.env.PUBLIC_URL + "/travel_future.csv", {
      download: true,
      header: true,
      complete: (results) => {
        const data = results.data
          .map((item) => ({
            ...item,
            latitude: parseFloat(item.latitude),
            longitude: parseFloat(item.longitude),
          }))
          .filter((item) => !isNaN(item.latitude) && !isNaN(item.longitude));

        setFutureLocations(data);
      },
    });
  }, []);

  // Calculate travel percentage whenever locations change
  useEffect(() => {
    calculateTravelPercentage(locations);
  }, [locations]);

  const calculateTravelPercentage = (locations) => {
    if (locations.length < 3) {
      setWorldTravelPercentage(0);
      return;
    }

    const points = locations.map((loc) => [loc.longitude, loc.latitude]);
    const turfPoints = turf.points(points);
    const convexHull = turf.convex(turfPoints);

    if (!convexHull) {
      setWorldTravelPercentage(0);
      return;
    }

    const areaVisited = turf.area(convexHull); // in square meters
    const worldAreaMeters = 510100000 * 1e6; // 510,100,000 km² in m²
    const percentageVisited = (areaVisited / worldAreaMeters) * 100;
    setWorldTravelPercentage(percentageVisited);
  };

  const getImageUrls = (cityName) => {
    const images = cityImages[cityName] || [];
    return images.map((img) => `${process.env.PUBLIC_URL}/photos/${cityName}/${img}`);
  };

  return (
    <div className="map-container">
      <div className="world-seen-info">
        <center><h1>Travel History</h1></center>
        <p>World Traveled: {worldTravelPercentage.toFixed(6)}%</p>
        <div className="legend">
          <div className="legend-item">
            <span className="legend-icon visited"></span> Visited Locations
          </div>
          <div className="legend-item">
            <span className="legend-icon future"></span> Future Visits
          </div>
        </div>
      </div>
      <MapContainer
        center={mapCenter}
        zoom={3}
        style={{ height: "100%", width: "100%" }}
        minZoom={3}
        maxZoom={20}
        maxBounds={[
          [-90, -180],
          [90, 180],
        ]}
        maxBoundsViscosity={1.0}
      >
        <MapBoundsEstimator onBoundsChange={(percentage) => console.log(`Visible area percentage: ${percentage.toFixed(2)}%`)} />
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {locations.map((location, index) => (
          <Marker
            key={`visited-${index}`}
            position={[location.latitude, location.longitude]}
            icon={CustomMarkerIcon}
            eventHandlers={{ click: () => setSelectedLocation(location) }}
          >
            <Popup>
              <div className="popup-content">
                <h3>{location.city}, {location.country}</h3>
                {getImageUrls(location.city).length > 0 ? (
                  getImageUrls(location.city).map((url, idx) => (
                    <img key={idx} src={url} alt={location.city} style={{ width: '100px', margin: '5px' }} />
                  ))
                ) : (
                  <p></p>
                )}
                <p>{location.description}</p>
              </div>
            </Popup>
          </Marker>
        ))}
        {futureLocations.map((location, index) => (
          <Marker
            key={`future-${index}`}
            position={[location.latitude, location.longitude]}
            icon={FutureMarkerIcon}
            eventHandlers={{ click: () => setSelectedLocation(location) }}
          >
            <Popup>
              <div className="popup-content">
                <h3>{location.city}, {location.country}</h3>
                <p>{location.description}</p>
              </div>
            </Popup>
          </Marker>
        ))}
        <Legend />
      </MapContainer>
      {selectedLocation && (
        <div className="selected-location-info">
          <h2>Selected Location</h2>
          <h3>{selectedLocation.city}</h3>
          {getImageUrls(selectedLocation.city).length > 0 ? (
            getImageUrls(selectedLocation.city).map((url, idx) => (
              <img key={idx} src={url} alt={selectedLocation.city} style={{ width: '200px', margin: '5px' }} />
            ))
          ) : (
            <p></p>
          )}
          <p>{selectedLocation.description}</p>
        </div>
      )}
    </div>
  );
}

export default TravelMap;
